import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from 'axios';
import Tost from '../Tost/Tost';
import Loader from '../Loader/Loader';
import { RxHamburgerMenu } from "react-icons/rx";
import { CgClose } from "react-icons/cg";
import { Dropdown } from 'primereact/dropdown';
import Navbar from '../Navbar/Navbar';
import Upload from '../../assets/SayIn/upload.jpg';

export default function Banner(props) {

    const [selectedC, setSelectedC] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        emailId: '',
        phoneNumber: '',
    });
    const [errorE, setErrorE] = useState(false);
    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const items = [
        {
            label: 'Business',
            command: () => setSelectedC('Business')
        },
        {
            label: 'Personal',
            command: () => setSelectedC('Personal')
        }
    ];

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleEmailChange = (event, i) => {
        const emailValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            'emailId': emailValue,
        }));

        if (!emailValue.trim()) {
            setErrorE('Email is required.');
        } else if (!isValidEmail(emailValue)) {
            setErrorE('Invalid email format or domain, length.');
        } else {
            setErrorE('');
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const words = email.endsWith('gmail.com');
        if (!words) {
            if (!emailRegex.test(email)) {
                return false;
            }
        }
        const domain = email.split('@')[1].toLowerCase();
        const hasDotCom = domain.endsWith('.com');
        const isDomainLengthValid = domain.length >= 8 && domain.length <= 20;
        return hasDotCom && isDomainLengthValid && domain;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const obj2 = { category: selectedC, systemName: 'VCheck', contactType: 'Mobile' };
        const mergedObj = { ...formData, ...obj2 };
        setFormData({
            firstName: '',
            lastName: '',
            emailId: '',
            phoneNumber: '',
        });
        setSelectedC('')
        const url = 'https://idm.althisolutions.com/signup';
        axios.post(url, mergedObj, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                setLoading(false);
                resetfield();
                setResponseData({ success: true, tittle: 'Success', bg: '#039856', message: response?.data?.message || 'Your confirmation was successful. Thank you for choosing us!', left: 3000 });
            })
            .catch(error => {
                setLoading(false);
                resetfield();
                setResponseData({ success: true, tittle: 'Error', bg: '#f44336', message: error?.response?.data?.message || 'Something went wrong. Please try again.', left: 3000 });
            });

    };


    const resetfield = () => {
        setFormData({
            firstname: '',
            lastname: '',
            emailId: '',
            phoneNumber: '',
        });
    }

    useEffect(() => {
        if (formData?.phoneNumber?.length < 10 || errorE || !formData?.firstName || !formData?.lastName || !selectedC) {
            setValid(false)
        } else {
            setValid(true)
        }
    }, [formData?.phoneNumber?.length < 10 || errorE || !formData?.firstName || !formData?.lastName || !selectedC])

    const [path, setPath] = useState('About Us');
    const [menuName, setMenuName] = useState('');


    const handleClick = (value) => {
        setMenuName(value);
        setPath(value);
    }

    const [isVisible, setIsVisible] = useState();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY) {
                setIsVisible(window.scrollY);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const options = items.map(item => ({
        label: item.label,
        value: item.label,
        command: item.command
    }));

    const handleChangeDrop = (e) => {
        const selectedItem = options.find(option => option.value === e.value);
        if (selectedItem && selectedItem.command) {
            selectedItem.command();
        }
    };


    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [virusDetails, setVirusDetails] = useState(null);
    const [fileName, setFileName] = useState(null); // State to store the file name
  
    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setFileName(file.name);
        }
    };

    // Handle form submission (API call)
    const handleUpload = async () => {
        setLoading(true);
        if (!selectedFile) {
            setLoading(false);
            alert("Please select a file first!");
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile); // Append the file correctly to the formData

        try {
            const response = await axios.post("https://api.vcheckk.com/file/scan/upload", formData, {
                headers: {
                    "api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "multipart/form-data"
                }
            });


            if (response.status === 200) {
                const virusDetails = response.data['virus-details'];

                setLoading(false);
                if (virusDetails) {
                    setVirusDetails(virusDetails);
                    setUploadStatus("Virus File detected");
                } else {
                    setUploadStatus("No Virus detected");
                }
            }
        } catch (error) {
            setLoading(true);
            setUploadStatus("Upload failed.");
            if (error.response) {
                alert("Error: " + error.response.data.message || "Upload failed");
                console.error("Error Response:", error.response);
            } else if (error.request) {
                alert("Network error: The request was made but no response was received.");
                console.error("Error Request:", error.request);
            } else {
                alert("Unexpected error: " + error.message);
                console.error("Error:", error.message);
            }
        }
    };
    useEffect(() => {
        if (uploadStatus && fileName) {
            const timer = setTimeout(() => {
                setUploadStatus(null);
                setFileName(null);
                setSelectedFile(null);
                
            }, 3000);
    
            return () => clearTimeout(timer);
        }
      
    }, [uploadStatus,fileName]);
    
    const statusBoxStyle = {
        display: "flex",
        justifyContent: "center",
        padding: "5px 8px",
        fontWeight: "bold",
        color: "#FFFFFF",
        borderRadius: "20px",
        fontSize: "12px",
        backgroundColor:
            uploadStatus === "Virus File detected" ? "red" :
                uploadStatus === "No Virus detected" ? "#00b700" :
                    "transparent",
    };

    const containerStyles = {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        position: 'relative',
        alignItems: "center",
        gap: "10px"
    };

    const virusDetailsStyle = {
        padding: "15px",
        backgroundColor: "rgb(211 216 221)", // Light gray background
        borderRadius: "8px", // Rounded corners
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow
        width: "300px",
        margin: "20px auto", // Center horizontally
        textAlign: "left",
        fontFamily: "'RaleWay',SansSerif",
        fontWeight:300
    };
    


    return (
        <>
            {responseData && <Tost tost={responseData} clearData={() => setResponseData(null)} />}
            {loading && <Loader />}

            <div style={{ display: 'flex', flexDirection: 'column' }}>

                <Navbar />
                

                <div className='f-Direction-T' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='ban-Le fleX' style={{ flexDirection: 'column', alignItems: 'end', justifyContent: 'center', gap: '10px', padding: windowWidth <= 800 ? '120px 0px 50px' : '' }}>
                        <div className='fleX w-full' style={{ maxWidth: '700px', flexDirection: 'column', gap: windowWidth <= 575 ? '10px' : windowWidth <= 800 ? '15px' : '' }}>
                            <div className="fleX w-full" style={{ gap: 'unset', alignItems: windowWidth <= 800 ? 'flex-start' : 'flex-end', justifyContent: 'flex-start', flexDirection: windowWidth <= 800 ? 'column' : '', gap: windowWidth <= 575 ? '10px' : windowWidth <= 800 ? '15px' : '', flexWrap: 'wrap' }}>
                                <div className="ban-C1">Shield your organization from malware</div>
                                <div className="ban-C2 line_Un-S foNT-l-M" style={{ position: windowWidth <= 800 ? '' : 'relative', bottom: '10px' }}>by integrating</div>
                            </div>
                            <div className="ban-C3 w-full" style={{ fontSize: windowWidth <= 575 ? '35px' : '' }}>Free Public APIs</div>
                            <div className="ban-C2 w-full line_Un-S foNT-l-M">for secure file uploads</div>
                            <div className="ban-C4 foNT-m-M w-full" style={{ padding: windowWidth <= 800 ? 'unset' : '' }}>
                                <h2 style={{ textAlign: "left" }}>Try it for free</h2>

                                <div style={{ ...containerStyles, textAlign: "center", }}>
                                    <div style={{
                                        cursor: "pointer",
                                        display: "",
                                        pointerEvents: "auto",
                                        width: "100px",
                                    }}
                                        onClick={() => document.getElementById("file-input").click()}
                                    >
                                        <img
                                            src={Upload}
                                            alt="Upload"
                                            style={{
                                                width: "100px",
                                                height: "80px",
                                                objectFit: "cover",
                                                display: "inline",
                                                pointerEvents: "none",
                                                margin: "0 auto",
                                            }}
                                        />
                                        <input
                                            id="file-input"
                                            type="file"

                                            accept=".pdf,.docx,.xls"
                                            style={{ display: "none" }}
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    {fileName && (<div style={{ fontSize: "16px", fontStyle: "arial", color: "#000000" }}>
                                        File Name: <strong>{fileName}</strong>
                                    </div>
                                    )}

                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <button
                                            onClick={handleUpload}
                                            style={{
                                                padding: "10px",
                                                width: "130px",
                                                color: "white",
                                                border: "none",
                                                cursor: "pointer",
                                                fontSize: "16px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            Upload
                                        </button>
                                    </div>

                                    {uploadStatus && (
                                        <div style={statusBoxStyle}>
                                            {uploadStatus}
                                        </div>
                                    )}
                                   
  


                                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='ban-Ri fleX jeS-C'>
                        <div className='fleX w-full' style={{ maxWidth: '700px', flexDirection: 'column' }}>
                            <div className='int-Sec fleX gaP-2'>
                                <label className='int-label' htmlFor='firstname'>First Name</label>
                                <InputText name='firstName' autoComplete="off" value={formData?.firstName} onChange={handleChange} className='in_Box' maxLength={16} />
                            </div>

                            <div className='int-Sec fleX gaP-2'>
                                <label className='int-label' htmlFor='lastname'>Last Name</label>
                                <InputText name='lastName' autoComplete="off" value={formData?.lastName} onChange={handleChange} className='in_Box' maxLength={16} />
                            </div>

                            <div className='int-Sec fleX gaP-2'>
                                <label className='int-label' htmlFor='email'>Email</label>
                                <InputText autoComplete="off" style={{ borderBottom: errorE ? '1px solid #FFFFFF' : '' }} name='email' value={formData?.emailId} onChange={handleEmailChange} className='in_Box' />
                                {errorE && <small className="p-error">{errorE}</small>}
                            </div>

                            <div className='int-Sec fleX gaP-2'>
                                <label className='int-label' htmlFor='phoneNumber'>Phone Number</label>
                                <InputText autoComplete="off" style={{ borderBottom: formData?.phoneNumber?.length < 10 && formData?.phoneNumber.length > 1 ? '1px solid #FFFFFF' : '' }} name='phoneNumber' keyfilter='int' value={formData?.phoneNumber} onChange={handleChange} className='in_Box' maxLength={10} />
                                {formData?.phoneNumber?.length < 10 && formData?.phoneNumber.length > 1 && <small className="p-error">Please enter a valid phone number.</small>}
                            </div>

                            <div className='int-Sec fleX gaP-2'>
                                <label className='int-label' htmlFor='purpose'>Category</label>
                                <Dropdown value={selectedC} options={options} onChange={handleChangeDrop} className="fleX in_Box" />
                            </div>

                            <div>
                                <Button
                                    label="Register"
                                    className='b_BTN'
                                    onClick={handleSubmit}
                                    style={{ background: valid ? '#FFFFFF' : '#ffffff94', position: 'relative', top: '35px' }}
                                    disabled={!valid}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div >


        </>
    )
}